.button {
    display: flex;
    justify-content: center;
    gap: 7rem;
    margin-bottom: 2rem;

    button {
        font-size: 1.5rem;

        .MuiSvgIcon-root {
            font-size: 2rem;
        }
    }
}
