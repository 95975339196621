@font-face {
    font-family: "Open Sans";
    src: url("../assets/fonts/OpenSans-Italic.eot");
    src: url("../assets/fonts/OpenSans-Italic.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/OpenSans-Italic.woff2") format("woff2"),
        url("../assets/fonts/OpenSans-Italic.woff") format("woff"),
        url("../assets/fonts/OpenSans-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: url("../assets/fonts/OpenSans-SemiBold.eot");
    src: url("../assets/fonts/OpenSans-SemiBold.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/OpenSans-SemiBold.woff2") format("woff2"),
        url("../assets/fonts/OpenSans-SemiBold.woff") format("woff"),
        url("../assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("../assets/fonts/OpenSans.eot");
    src: url("../assets/fonts/OpenSans.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/OpenSans.woff2") format("woff2"),
        url("../assets/fonts/OpenSans.woff") format("woff"),
        url("../assets/fonts/OpenSans.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("../assets/fonts/OpenSans-SemiBoldItalic.eot");
    src: url("../assets/fonts/OpenSans-SemiBoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/OpenSans-SemiBoldItalic.woff2") format("woff2"),
        url("../assets/fonts/OpenSans-SemiBoldItalic.woff") format("woff"),
        url("../assets/fonts/OpenSans-SemiBoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

/** Montserrat *

/* montserrat-regular - latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: url("../assets/fonts/Monserrat/montserrat-v18-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../assets/fonts/Monserrat/montserrat-v18-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-regular.svg#Montserrat")
            format("svg"); /* Legacy iOS */
}
/* montserrat-italic - latin */
@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 400;
    src: url("../assets/fonts/Monserrat/montserrat-v18-latin-italic.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../assets/fonts/Monserrat/montserrat-v18-latin-italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-italic.woff")
            format("woff"),
        /* Modern Browsers */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-italic.svg#Montserrat")
            format("svg"); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    src: url("../assets/fonts/Monserrat/montserrat-v18-latin-600.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../assets/fonts/Monserrat/montserrat-v18-latin-600.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-600.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-600.woff")
            format("woff"),
        /* Modern Browsers */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-600.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-600.svg#Montserrat")
            format("svg"); /* Legacy iOS */
}
/* montserrat-600italic - latin */
@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 600;
    src: url("../assets/fonts/Monserrat/montserrat-v18-latin-600italic.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../assets/fonts/Monserrat/montserrat-v18-latin-600italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-600italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-600italic.woff")
            format("woff"),
        /* Modern Browsers */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-600italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../assets/fonts/Monserrat/montserrat-v18-latin-600italic.svg#Montserrat")
            format("svg"); /* Legacy iOS */
}

/** Poppins */

/* poppins-regular - latin */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    src: url("../assets/fonts/Poppins/poppins-v15-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../assets/fonts/Poppins/poppins-v15-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../assets/fonts/Poppins/poppins-v15-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../assets/fonts/Poppins/poppins-v15-latin-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("../assets/fonts/Poppins/poppins-v15-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../assets/fonts/Poppins/poppins-v15-latin-regular.svg#Poppins")
            format("svg"); /* Legacy iOS */
}
/* poppins-italic - latin */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 400;
    src: url("../assets/fonts/Poppins/poppins-v15-latin-italic.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../assets/fonts/Poppins/poppins-v15-latin-italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../assets/fonts/Poppins/poppins-v15-latin-italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../assets/fonts/Poppins/poppins-v15-latin-italic.woff")
            format("woff"),
        /* Modern Browsers */
            url("../assets/fonts/Poppins/poppins-v15-latin-italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../assets/fonts/Poppins/poppins-v15-latin-italic.svg#Poppins")
            format("svg"); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    src: url("../assets/fonts/Poppins/poppins-v15-latin-600.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../assets/fonts/Poppins/poppins-v15-latin-600.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../assets/fonts/Poppins/poppins-v15-latin-600.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../assets/fonts/Poppins/poppins-v15-latin-600.woff")
            format("woff"),
        /* Modern Browsers */
            url("../assets/fonts/Poppins/poppins-v15-latin-600.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../assets/fonts/Poppins/poppins-v15-latin-600.svg#Poppins")
            format("svg"); /* Legacy iOS */
}
/* poppins-600italic - latin */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 600;
    src: url("../assets/fonts/Poppins/poppins-v15-latin-600italic.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../assets/fonts/Poppins/poppins-v15-latin-600italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../assets/fonts/Poppins/poppins-v15-latin-600italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../assets/fonts/Poppins/poppins-v15-latin-600italic.woff")
            format("woff"),
        /* Modern Browsers */
            url("../assets/fonts/Poppins/poppins-v15-latin-600italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../assets/fonts/Poppins/poppins-v15-latin-600italic.svg#Poppins")
            format("svg"); /* Legacy iOS */
}
