.categorize-order-articles-modal {
    .MuiPaper-root {
        min-width: 60rem;
        height: 60rem;
        text-align: center;
    }
    .MuiTypography-root {
        font-size: 1.5rem;
    }
}

.categorize-order-articles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .articles-to-match {
        display: flex;
        flex-direction: column;

        &__title {
            align-self: center;
            font-weight: bold;
            margin-bottom: 1rem;
        }

        &__articles {
            align-self: center;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .article-to-match {
                cursor: pointer;
                display: flex;
                flex-direction: column;

                &__label {
                    padding: 0.5rem;
                    width: fit-content;
                    &:hover,
                    &--selected {
                        background-color: #20274a;
                        color: white;
                    }
                }

                &__selected-match {
                    display: flex;
                    gap: 0.25rem;
                    margin-left: 0.5rem;

                    span {
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    .article-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &__items {
            display: flex;
            flex-direction: column;
            border: 1px solid lightgray;

            .article-list-item {
                cursor: pointer;
                padding: 0.5rem;
                font-weight: bold;
                border-bottom: 1px solid lightgray;

                &:hover {
                    background-color: #20274a;
                    color: white;
                }
            }
        }
    }
}

@media screen and (max-width: 950px) {
    .categorize-order-articles-modal {
        .MuiPaper-root {
            min-width: 40rem;
        }
    }
}

