.categorize-order-articles-modal .MuiPaper-root {
  min-width: 60rem;
  height: 60rem;
  text-align: center;
}
.categorize-order-articles-modal .MuiTypography-root {
  font-size: 1.5rem;
}

.categorize-order-articles {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.categorize-order-articles .articles-to-match {
  display: flex;
  flex-direction: column;
}
.categorize-order-articles .articles-to-match__title {
  align-self: center;
  font-weight: bold;
  margin-bottom: 1rem;
}
.categorize-order-articles .articles-to-match__articles {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.categorize-order-articles .articles-to-match__articles .article-to-match {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.categorize-order-articles .articles-to-match__articles .article-to-match__label {
  padding: 0.5rem;
  width: fit-content;
}
.categorize-order-articles .articles-to-match__articles .article-to-match__label:hover, .categorize-order-articles .articles-to-match__articles .article-to-match__label--selected {
  background-color: #20274a;
  color: white;
}
.categorize-order-articles .articles-to-match__articles .article-to-match__selected-match {
  display: flex;
  gap: 0.25rem;
  margin-left: 0.5rem;
}
.categorize-order-articles .articles-to-match__articles .article-to-match__selected-match span {
  margin-top: 5px;
}
.categorize-order-articles .article-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.categorize-order-articles .article-list__items {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
}
.categorize-order-articles .article-list__items .article-list-item {
  cursor: pointer;
  padding: 0.5rem;
  font-weight: bold;
  border-bottom: 1px solid lightgray;
}
.categorize-order-articles .article-list__items .article-list-item:hover {
  background-color: #20274a;
  color: white;
}

@media screen and (max-width: 950px) {
  .categorize-order-articles-modal .MuiPaper-root {
    min-width: 40rem;
  }
}
.button {
  display: flex;
  justify-content: center;
  gap: 7rem;
  margin-bottom: 2rem;
}
.button button {
  font-size: 1.5rem;
}
.button button .MuiSvgIcon-root {
  font-size: 2rem;
}